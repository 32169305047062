@import '../../../../scss/theme-bootstrap';

.site-header-formatter {
  height: $gnav-mobile-height;
  position: relative;
  @include breakpoint($landscape-only) {
    height: $gnav-height;
  }
  .ada-enabled & {
    .gnav-arrow-down-label {
      display: none;
      @include breakpoint($landscape-only) {
        display: inline;
      }
    }
  }
  .gnav-arrow-down-label {
    display: none;
  }
  .gnav-arrow-down-icon {
    stroke-width: 20px;
    stroke: $color-white-op50;
    height: 7px;
    width: 14px;
  }

  .gnav-offers-hidden &,
  &.header-offers-banner-hidden,
  &.site-header-formatter--sticky {
    height: $navbar-height-default-mob;
    @include breakpoint($landscape-only) {
      height: $navbar-height-default;
    }
  }
  &--large-logo & {
    height: $navbar-height-large-logo-mob;
    @include breakpoint($landscape-only) {
      height: $navbar-height-large-logo;
    }
    &:not(.header-offers-banner-hidden) {
      height: $navbar-height-big-logo-offer-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-big-logo-offer;
      }
    }
  }
  &--large-logo.site-header-formatter--hidden.site-header-formatter--sticky & {
    height: $navbar-height-large-logo-mob;
    @include breakpoint($landscape-only) {
      height: $navbar-height-large-logo;
    }
    &:not(.header-offers-banner-hidden) {
      height: $navbar-height-big-logo-offer-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-big-logo-offer;
      }
    }
  }
  &--large-logo:not(.site-header-formatter--hidden):not(.site-header-formatter--sticky) & {
    @include transition(height $logo-animation-duration $logo-animation-timing-function);
  }
  &__inner {
    background-color: $color-white;
    position: relative;
    top: auto;
    width: 100%;
    z-index: $gnav-fixed-z-index;
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      z-index: $gnav-fixed-z-index + 1;
    }
    .site-header-formatter--hidden & {
      @include transform(translateY(-100%));
      position: fixed;
    }
    // Hide the sticky Nav when scrolling down.
    .site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transform(translateY(-100%));
      transition: none;
      position: fixed;
    }
    .site-header-formatter--sticky & {
      position: fixed;
      background-color: $color-white;
    }
    html:not(.active-gnav) .site-header-formatter--sticky:not(.site-header-formatter--hidden) & {
      @include transform(translateY(0));
      @include transition(transform $sticky-animation-duration $sticky-animation-timing-function);
    }
    .site-header-formatter--hidden.site-header-formatter--sticky.site-header-formatter--after-pos
      & {
      @include transition(transform $sticky-animation-duration $sticky-animation-timing-function);
    }
    .site-header-formatter--up.site-header-formatter--hidden.site-header-formatter--sticky & {
      @include transition(transform $sticky-animation-duration $sticky-animation-timing-function);
    }
    .site-header-formatter--large-logo & {
      height: $navbar-height-large-logo-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-large-logo;
      }
    }
    .site-header-formatter--large-logo:not(.gnav-offers-hidden) & {
      height: $navbar-height-big-logo-offer-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-big-logo-offer;
      }
    }
    .site-header-formatter--large-logo.site-header-formatter--sticky & {
      height: $navbar-height-default-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-default;
      }
    }
    .site-header-formatter--large-logo:not(.site-header-formatter--hidden):not(.site-header-formatter--sticky)
      & {
      @include transition(height $logo-animation-duration $logo-animation-timing-function);
    }
    .gnav-util-overlay-active & {
      @include breakpoint($landscape-only) {
        z-index: $gnav-fixed-z-index + 1;
      }
      &.site-header-formatter--sticky {
        @include breakpoint($landscape-only) {
          z-index: $gnav-fixed-z-index + 1;
        }
      }
    }
    .gnav-transparent & {
      border: none;
      .active-gnav &,
      .active-utility-overlay & {
        border-bottom: 1px solid $color-lighter-gray;
      }
    }
  }
  &__inner-content {
    @include breakpoint($landscape-only) {
      margin: 0 auto;
      position: relative;
    }
  }
  &__top {
    @include transition(height 0.25s ease, opacity 0.25s ease);
    position: relative;
    height: 100%;
    opacity: 1;
    .header-offers-banner-hidden &,
    .site-header-formatter--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__countdown-block {
    .site-header-formatter--sticky & {
      height: 0;
      opacity: 0;
    }
  }
  &__header {
    @include swap_direction(padding, 0 $container-pad-small 0 $container-pad-small);
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    border: none;
    width: 100%;
    @include breakpoint($landscape-only) {
      max-width: $container-max-width-xlarge;
      padding: 0;
      border-bottom: none;
    }
  }
  &__offers {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    display: block;
    .site-header-formatter--sticky & {
      display: none;
    }
  }
  &__countdown-block {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    display: block;
    position: relative;
  }
  &__bar {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-flow: row wrap;
    @include breakpoint($landscape-only) {
      justify-content: normal;
    }
  }
  &__logo-wrap {
    position: relative;
    display: flex;
    order: 3;
    width: 100%;
    margin: 0;
    height: auto;
    .site-header-formatter--large-logo & {
      height: $navbar-logo-wrap-mob-height;
      margin: 0;
      @include breakpoint($landscape-only) {
        height: $navbar-logo-wrap-height;
        margin: 0 $container-pad-landscape;
      }
    }
    .site-header-formatter--sticky &,
    .site-header-formatter--large-logo.site-header-formatter--sticky & {
      height: auto;
      margin: 0;
    }
  }
  &__logo {
    @include transform(translateY(-100%));
    display: block;
    line-height: 1;
    order: 3;
    text-align: center;
    position: absolute;
    #{$ldirection}: calc(50% - #{$width-logo-default-mob}/ 2);
    width: $width-logo-default-mob;
    height: $navbar-height-default-mob;
    top: 0;
    @include breakpoint($landscape-only) {
      #{$ldirection}: calc(50% - #{$width-logo-default}/ 2);
      width: $width-logo-default;
      height: $navbar-height-default;
    }
    .site-header-formatter--large-logo & {
      @include transform(translateY(0));
      width: 100%;
      height: 100%;
    }
    .site-header-formatter--sticky & {
      @include transform(translateY(-100%));
      width: $width-logo-default-mob;
      height: $navbar-height-default-mob;
      top: 0;
      @include breakpoint($landscape-only) {
        height: $navbar-height-default;
        width: $width-logo-default;
      }
    }
    .site-header-formatter--large-logo:not(.site-header-formatter--hidden):not(.site-header-formatter--sticky)
      & {
      @include transition(all $logo-animation-duration ease-in);
      #{$ldirection}: 0;
    }
    img {
      width: 100%;
    }
    svg {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      @include breakpoint($landscape-only) {
        height: 100%;
        width: 100%;
      }
    }
  }
  &__sections-main-links {
    height: fit-content;
    width: fit-content;
    display: flex;
    height: -moz-fit-content;
    width: -moz-fit-content;
    align-items: flex-start;
    @include breakpoint($landscape-only) {
      top: $gnav-panel-content-pad-top;
      position: relative;
    }
  }
  &__account-link-mob {
    flex: 0 0 auto;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__extra-link {
    @include typography-body-text();
    font-size: $nav-label-font-size;
    color: $color-white-op50;
    line-height: 1.89;
    text-decoration: none;
    letter-spacing: 0.03em;
    &:focus {
      color: $color-white;
      box-shadow: 0 1px 0 0 $color-white;
    }
  }
  &__links {
    &-icon {
      display: none;
    }
    &-link {
      @include typography-link-text();
      display: flex;
      align-items: center;
      height: 31px;
      text-decoration: none;
      padding-#{$ldirection}: $container-pad-small;
      @include breakpoint($landscape-only) {
        padding-#{$ldirection}: $container-pad-landscape;
      }
    }
    a {
      color: $color-white;
      text-decoration: none;
    }
  }
  &__links-container {
    display: none;
    @include breakpoint($landscape-only) {
      @include transition(
        opacity 0.7s cubic-bezier(0.5, 0, 0.5, 1) 0.4s,
        #{$ldirection} $gnav-panel-animation-duration ease-in-out
      );
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 0;
      #{$ldirection}: -100%;
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      background-color: $color-core-black;
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections & {
      z-index: 1001;
      @include breakpoint($landscape-only) {
        opacity: 1;
        visibility: visible;
        #{$ldirection}: 0;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked)
      ~ .site-header-formatter__sections.search-panel-visible
      & {
      display: none;
    }
  }
  &-search-input-field {
    display: block;
    width: 100%;
    color: $color-core-black;
    font-size: 14px;
  }
  &__sections {
    @include transition(
      #{$ldirection} $gnav-panel-animation-duration $gnav-panel-animation-timing-function
    );
    background-color: $color-core-black;
    padding-#{$ldirection}: $container-pad-small;
    height: auto;
    #{$ldirection}: -100%;
    position: fixed;
    top: -1px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index: $gnav-fixed-z-index;
    padding-top: 45px;
    .site-header-formatter--sticky & {
      transition: none;
    }
    &:after {
      content: '';
      background: $color-core-black;
      height: 100vh;
      @include breakpoint($landscape-only) {
        @include transition(
          #{$ldirection} $gnav-panel-animation-duration ease-in-out,
          width $gnav-panel-animation-duration ease-in-out
        );
        content: '';
        background: $color-core-black;
        height: 100vh;
        #{$ldirection}: -160%;
        width: 0;
        top: 0;
        position: fixed;
        z-index: 993;
      }
    }
    @include breakpoint($landscape-only) {
      @include swap_direction(padding, 0 0 0 $container-pad-landscape);
      position: relative;
      margin-top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      order: 1;
      height: $navbar-height-default;
      width: $gnav-first-panel-width;
      transition: none;
      background: transparent;
      overflow-y: unset;
      #{$ldirection}: 0;
      padding-top: 0;
    }
    &.search-panel-visible {
      @include breakpoint($landscape-only) {
        width: $gnav-search-panel-width;
      }
    }
    .site-header-formatter__mobile-trigger:checked ~ & {
      @include transition(#{$ldirection} 0.5s ease-in-out);
      margin-top: 0;
      background-color: $color-core-black;
      height: 100%;
      #{$ldirection}: 0;

      .gnav-offers-hidden &,
      .header-offers-banner-hidden &,
      .site-header-formatter--sticky & {
        height: 100%;
      }
      @include breakpoint($landscape-only) {
        position: relative;
        display: block;
        width: calc(100% - 2 * #{$container-pad-landscape} - 198px);
        height: 100%;
        background-color: unset;
        margin-top: 0;
        padding-top: 0;
      }
      .header-offers-banner-hidden &,
      .site-header-formatter--sticky &,
      .gnav-offers-hidden & {
        margin-top: 0;
        background-color: $color-core-black;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ & {
      @include breakpoint($landscape-only) {
        z-index: unset;
        display: block;
        overflow-y: inherit;
        top: 0;
        margin-top: 0;
        height: 100%;
        position: fixed;
        #{$ldirection}: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 995;
      }
      .gnav-transparent & {
        @include breakpoint($landscape-only) {
          background: transparent;
        }
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ &:after {
      @include breakpoint($landscape-only) {
        @include transition(
          #{$ldirection} $gnav-panel-animation-duration $gnav-panel-animation-timing-function,
          width $gnav-panel-animation-duration $gnav-panel-animation-timing-function
        );
        #{$ldirection}: 0;
        width: $gnav-first-panel-width;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked) ~ &.search-panel-visible:after {
      @include breakpoint($landscape-only) {
        width: $gnav-search-panel-width;
      }
    }
  }
  &--close-icon {
    &,
    input[type='radio'] ~ & {
      position: fixed;
      visibility: hidden;
      opacity: 0;
      justify-content: center;
      top: 12px;
      width: auto;
      height: auto;
      #{$rdirection}: $container-pad-small;
      background-color: $color-core-black;
      padding: 8px 0;
      z-index: 100;
      @include breakpoint($landscape-only) {
        display: none;
      }
      .content-panel-visible & {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity 1.5s;
      }
      &::before {
        display: none;
      }
      .icon {
        width: 14px;
        height: 14px;
        fill: $color-white;
      }
    }
    input[type='radio']:checked ~ & {
      &::after {
        display: none;
      }
    }
  }
  // Utility styles
  &__utilities {
    display: flex;
    align-items: center;
    flex-direction: row;
    order: 2;
    flex: 1;
    height: $navbar-height-default-mob;
    justify-content: flex-end;
    @include breakpoint($landscape-only) {
      font-size: 15px;
      height: $navbar-height-default;
      padding-#{$rdirection}: $container-pad-landscape;
    }
    .site-header-formatter--large-logo & {
      height: $navbar-main-links-height;
      @include breakpoint($landscape-only) {
        height: $navbar-height-default;
      }
    }
    .site-header-formatter--large-logo.site-header-formatter--sticky & {
      height: $navbar-height-default-mob;
      @include breakpoint($landscape-only) {
        height: $navbar-height-default;
      }
    }
    &-item {
      padding-#{$rdirection}: $navbar-main-links-gap;
      display: inline-flex;
      &:last-child {
        padding-#{$rdirection}: 0;
      }
    }
    .gnav-util {
      &__content {
        display: none;
        position: fixed;
        top: $navbar-mobile-height;
        overflow-x: hidden;
        #{$rdirection}: 0;
        background: $color-core-black;
        padding: 0 30px;
        width: 100%;
        z-index: 999;
        @include breakpoint($landscape-only) {
          width: 390px;
          top: $gnav-height;
        }
        .gnav-offers-hidden &,
        .header-offers-banner-hidden &,
        .site-header-formatter--sticky & {
          top: $navbar-height;
        }
        &-header-title {
          font-size: 16px;
          text-align: center;
          padding: 18px 0;
        }
        &-header {
          &-close {
            display: block;
            text-align: $ldirection;
            margin: 0 18px;
            position: absolute;
            .icon--close {
              height: 14px;
              width: 14px;
              stroke-width: 50px;
              stroke: $color-core-black;
            }
          }
          .cart-loading & {
            padding: 10px 0 15px;
          }
        }
      }
      &__icon {
        color: $color-core-black;
        position: relative;
        text-decoration: none;
        height: 100%;
        display: flex;
        .icon {
          width: 18px;
          height: 18px;
          &:before {
            font-size: 30px;
          }
        }
        &:hover {
          text-decoration: none;
        }
      }
      &__label {
        display: none;
      }
      &-trigger:checked ~ .gnav-util__content {
        @include breakpoint($landscape-only) {
          display: block;
        }
      }
      &-trigger:checked ~ .gnav-util__icon {
        .gnav-util__label {
          @include breakpoint($landscape-only) {
            box-shadow: 0 1px 0 0 $color-core-black;
          }
        }
      }
    }
    &-logo {
      margin: 0;
      position: relative;
      width: auto;
      line-height: 1;
    }
    &-icon {
      height: 18px;
      &--logo {
        width: 148px;
      }
      &--cart {
        width: 18px;
      }
    }
    &-link {
      @include typography-link-text;
      font-size: $nav-label-font-size;
      color: $color-core-black;
      text-decoration: none;
      display: none;
      @include breakpoint($landscape-only) {
        display: block;
      }
    }
  }
  // Mobile menu trigger (Hamburger).
  &__menu-icon {
    svg.site-header-formatter__menu-icon-show {
      .site-header-formatter--large-logo & {
        display: block;
      }
    }
    @include breakpoint($landscape-only) {
      display: none;
    }
    span.icon-lines {
      @include border-radius(3px);
      @include transition(transform 0.5s ease, background 0.5s ease, opacity 0.55s ease);
      display: block;
      width: 18px;
      height: 2px;
      position: relative;
      background: $color-core-black;
      z-index: 1;
      &:first-child {
        margin-bottom: 4px;
      }
      .site-header-formatter__mobile-trigger:checked ~ .site-header-formatter__utilities & {
        @include transform(rotate(45deg) translate(1px, -1px));
        opacity: 1;
        background: $color-core-black;
        margin-bottom: 0;
        &:nth-last-child(2) {
          @include transform(rotate(-45deg) translate(-1px, 2px));
        }
      }
    }
    &-label {
      @include typography-body-text();
      font-size: $nav-label-font-size;
      display: none;
      color: $color-core-black;
      .site-header-formatter--large-logo & {
        display: block;
      }
      .site-header-formatter--sticky & {
        display: none;
      }
      @include breakpoint($landscape-only) {
        display: none;
      }
    }
    svg {
      .site-header-formatter--large-logo & {
        display: none;
      }
      .site-header-formatter--sticky & {
        display: block;
      }
      width: $nav-icon-width;
      height: $nav-icon-height;
    }
  }
  &__search-icon {
    margin-#{$ldirection}: 20px;
    cursor: pointer;
    @include breakpoint($landscape-only) {
      display: none;
    }
    svg {
      width: $nav-icon-width;
      height: $nav-icon-height;
      @include breakpoint($landscape-only) {
        height: 15px;
      }
      .site-header-formatter--large-logo {
        display: none;
      }
      .site-header-formatter--sticky & {
        display: block;
      }
    }
  }
  &__search {
    padding: 0;
    @include breakpoint($landscape-only) {
      padding-#{$rdirection}: 25px;
    }
    .esearch-nav-icon {
      display: none;
      @include breakpoint($landscape-only) {
        display: flex;
      }
    }
  }
  &__locator,
  &__account {
    display: none;
    @include breakpoint($landscape-only) {
      display: inline-flex;
    }
  }

  // Shared overlay for the gnav and utility
  &-overlay {
    &,
    input[type='radio'] ~ & {
      @include transition(opacity 0.5s ease, visibility 0.5s ease);
      background-color: $color-gray-op60;
      cursor: pointer;
      display: none;
      height: 0;
      #{$ldirection}: 50%;
      transform: translateX(-50%);
      opacity: 0;
      position: fixed;
      top: 0;
      visibility: hidden;
      width: 100%;
      max-width: $container-max-width-xlarge;
      z-index: $gnav-fixed-z-index - 2;
      &::after,
      &::before {
        display: none;
        visibility: hidden;
      }
    }
    .header-nav-section__trigger:checked + .header-nav-section__section + &,
    .gnav-util-trigger:checked + & {
      height: 100%;
      opacity: 0.75;
      visibility: visible;
      top: $gnav-mobile-height;
      @include breakpoint($landscape-only) {
        display: block;
        top: 0;
      }
      .gnav-offers-hidden &,
      .header-offers-banner-hidden &,
      .site-header-formatter--sticky & {
        top: $navbar-mobile-height;
        @include breakpoint($landscape-only) {
          top: 0;
        }
      }
    }
    .site-header-formatter--sticky &,
    .gnav-offers-hidden & {
      top: $navbar-mobile-height;
      @include breakpoint($landscape-only) {
        top: 0;
      }
    }
  }
  &__language-chooser {
    padding-#{$rdirection}: 10px;
    @include breakpoint($landscape-only) {
      padding-#{$rdirection}: 25px;
    }
  }
  .language-picker {
    &__border {
      visibility: hidden;
      &:after {
        content: '/';
        visibility: visible;
        margin-#{$ldirection}: -4px;
      }
    }
    &__link {
      text-decoration: none;
    }
  }
  .site-utils__menu {
    margin: 0;
  }
}

// Overrides for specific sections and states applied to html and body.
html.active-utility-overlay,
html.active-gnav {
  overflow: hidden; // No scrolling the body when fixed nav sections are active.
  overflow-y: hidden; // ios safari.
  @include breakpoint($landscape-only) {
    overflow: inherit;
  }
  .site-header-formatter--hidden.site-header-formatter--sticky .site-header-formatter__inner {
    @include transform(unset);
  }
  .LPMoverlay,
  .LPMcontainer {
    visibility: hidden;
  }
}

body {
  .active-gnav & {
    max-width: 100%;
    overflow: hidden; // No scrolling the body when fixed nav sections are active.
    overflow-y: hidden; // ios safari.
    width: 100%;
    position: relative;
  }
}

.header-sticky-hidden {
  &.site-header-formatter--sticky {
    html:not(.active-gnav) & {
      .site-header-formatter__inner {
        position: relative;
        transition: none;
        transform: none;
        top: 0;
      }
    }
    .site-header-formatter__top {
      height: 100%;
      opacity: 1;
    }
    .site-header-formatter__offers {
      display: block;
    }
  }
  .header-offers-banner-hidden {
    .site-header-formatter__top {
      height: 100%;
      opacity: 1;
    }
  }
}
